import React from "react";

function LastenRasitusvammatP4() {
  return (
    <p>
      Yhteistä apofysiiteille: Rasituksen vähentäminen / tauottaminen,
      kylmähoito, venyttely ja hieronta helpottaa oireita. Pidemmällä
      aikatähtäimellä liikkuvuusharjoitukset sekä lihasvoimaharjoitteet
      lihashallinnan parantamiseksi ovat hyviä aputoimia.
    </p>
  );
}

export default LastenRasitusvammatP4;
