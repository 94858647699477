import React from "react";

function LastenRasitusvammatP2() {
  return (
    <p>
      Alkuun kipua tuntuu vain rasituksen yhteydessä, mutta pahentuessaan se
      tuntuu myös rasituksen jälkeen. Hypyt sekä äkkikiihdytykset ja
      -jarrutukset pahentavat vaivaa. Kantakorotus voi helpottaa kipua, samoin
      kylmähoito.
    </p>
  );
}

export default LastenRasitusvammatP2;
