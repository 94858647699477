import React from "react";

function KasvukivutP1() {
  return (
    <p>
      On nukkumaanmenoaika, hampaat harjattu ja iltasadut luettu. ”Äiti,
      voisitko hieroa mun jalkoja, niitä särkee”. Kasvukipuja esiintyy jopa 50 %
      lapsista. Varsinaista syytä niille ei tiedetä. Tietokirjallisuuden mukaan
      kipuja esiintyy 3–12-vuotiailla lapsilla, eniten 5–8-vuotiailla. Omalla
      kohdalla muistelisin, että mimmit ovat valittaneet varsinkin iltaisin
      kipua jaloissa kutakuinkin siitä lähtien kun ovat oppineet puhumaan.
      Ollaan kehitetty jopa jalkojenhoitolaulu, jonka takuulla muistan vielä
      eläkkeelläkin! Takaisin asiaan. Kasvukivut tosiaan tulevat kiusaamaan
      useimmiten juuri nukkumaanmenon yhteydessä. Kipua on polvitaipeissa,
      jalkaterissä, säären etupuolella, joskus pohkeissa ja reisissäkin.
      Nimestään huolimatta tutkimusten mukaan on todennäköisempää, että kivut
      eivät johdu kasvamisesta vaan enemmänkin rasituksesta. Alue voi olla
      kosketusarka tai siinä voi olla tuntopuutoksia tai jopa kyhmyjä. Mikäli
      kyhmy pysyy paikoillaan pidempään, kannattaa olla yhteydessä lääkäriin –
      kasvukipukyhmyt tulevat ja menevät vaihtaen paikkaa. No mikä kasvukipuihin
      sitten auttaa? Ja voiko niitä ennaltaehkäistä? Valitettavasti kipuja ei
      pysty ennaltaehkäisemään. Toiset lapset kipuilevat, toiset eivät. Eikä
      D-vitamiinilisillä tai kalkkilisillä ole vaikutusta asiaan. Hieronta voi
      helpottaa oireita. Kevyehkö sively kämmenellä alhaalta ylöspäin
      (periferiasta sydämeenpäin) on hyvä hierontaote. Myös lämpö voi auttaa.
      Joitakin auttaa kylmä (meillä ei toiminut). Ja tietenkin venyttelyt.
      Viimeisenä oljenkortena on tulehduskipulääkkeet eli käytännössä Burana tai
      Pronaxen. Hyvä puoli on, että kasvukivut eivät ole vaarallisia ja että ne
      menevät kyllä ohi! Kukaan ei kasva loputtomiin (sanoo 153 cm nainen).
      Toivottavasti jaksoit lukea ja sisältö oli mielenkiintoista. Jatkan
      aiheesta pian ja kerron lasten/nuorten tavallisimmista rasitusvammoista.
    </p>
  );
}

export default KasvukivutP1;
