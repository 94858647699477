import React from "react";

function HermotP1() {
  return (
    <p>
      Hyvin monella puutuu joskus sormet tai on hermokipua esimerkiksi pakarassa
      tai jalassa. Mietit ja pohdit, mistä vaiva johtuu. Tai menet perinteisellä
      ajatuksella ” Iskias vaivaa taas”. Mikäli pääset lääkärille / fyssarille
      asti, niin kysymyksiä satelee – ”Missä sormessa puutuminen tuntuu,
      pystytkö kävelemään varpailla, entä kantapäillä, onko ihotunto normaali”.
      Mitä ihmettä he oikein hakevat noilla kysymyksillä? Avaan asiaa hiukan –
      pintapuoleisesti. Korostan vielä, ettei mennä syvälle asian ytimeen vaan
      pidetään asia mahdollisimman yksinkertaisena.
    </p>
  );
}

export default HermotP1;
