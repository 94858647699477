import React from "react";

function Katri() {
  return (
    <div
      data-aos="zoom-in-right"
      data-aos-duration="4000"
      className="paragraph"
    >
      <h2> Katri</h2>
      Olen ollut erittäin tyytyväinen LennuSporttiin. Selkäni oli jumissa ja
      olkapäässä vihlovaa kipua. Muutaman kerran käytyäni Lennun luona kivut ja
      vaivat alkoivat häviämään ja tällä hetkellä selkä ja olkapää ovat
      kunnossa. Hieronta napakkaa ja otteet hyvät. Suosittelen lämpimästi.
    </div>
  );
}

export default Katri;
