import React from "react";
import "../Asiakaskokemukset.css";

function Janna() {
  return (
    <div
      data-aos="zoom-in-right "
      data-aos-duration="1000"
      className="paragraph"
    >
      <h2> Janna</h2>
      Lennun hieronnassa olen rentoutunut niin henkisesti kuin fyysisesti.
      Tunnelma hieronnassa on rento ja rauhoittava. Lennu huomioi otteissaan
      hierottavan tuntemukset juuri oikein, kuitenkin pysyen otteissa jotka
      auttavat. Hieronnan jälkeen huomasin todella kuinka liikeradat
      laajentuivat ja kivut helpottuivat. Hieronnassa syntyy tunnelma, että
      tulee kohdatuksi yksilönä ja juuri omien tarpeiden mukaan.
    </div>
  );
}

export default Janna;
