import React from "react";

function DryNeedlingP3() {
  return (
    <p>
      Dry needling-hoidossa käsitellään triggerpisteitä. Välillä käytetään myös
      pinnallisempaa neuloitusta. <br />
      Dry Needling-hoito stimuloi immuunijärjestelmää paikallisesti ja laukaisee
      kehon omia paranemisprosesseja. <br />
      Hoito poikkeaa kiinalaisesta akupunktiosta siten, että akupunktiossa
      hoidetaan meridiaaneilla eli
      <br />
      energiakanavilla olevia akupisteitä kiinalaisen lääketieteen mukaisesti.
      Dry needling ei siis ole akupunktiohoitoa.
      <br />
      <br />
      Dry Needling-käsittelyssä etsitään ensin sormin tunnustelemalla kireä
      lihasjuoste ja triggerpiste johon neula laitetaan. <br />
      Hoidolla voidaan käsitellä tehokkaasti myös lihaksia, jotka ovat syvällä
      pinnallisten lihaskerrosten alla. Syvempien <br />
      lihasten kohdalla määritetään tarkasti kehon anatomiset rakenteet, kuten
      hermojen ja verisuonten kulkureitit. <br />
      Kun neula on asetettu paikoilleen saattaa tuntua hetkellistä paineen
      tunnetta ja joskus myös paikallinen lihasnykäys. <br />
      Lihaksen supistuminen aiheuttaa lihasnykäyksen neulan osuessa
      herkistyneeseen triggerpisteeseen. Neulalla voidaan tehdä <br />
      dynaamista eli edestakaista liikettä, jolloin herkistyminen vähentyy ja
      lopulta poistuu kokonaan. <br />
      Tällöin paikallinen kipu sekä heijastekipu häviää ja käsitellyn lihaksen
      toiminta palautuu ennalleen.
    </p>
  );
}

export default DryNeedlingP3;
