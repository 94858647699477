import React from "react";
import "../Blogi.css";
import HieronnatP1 from "./HieronnatP1";
import HieronnatP2 from "./HieronnatP2";
import HieronnatP3 from "./HieronnatP3";
const Hieronnat = () => {
  return (
    <div
      className="alku-container"
      style={{ background: "linear-gradient(to left, #1AAFE7 10%, white 90%)" }}
    >
      <div className="para">
        <h1>Klassinen hieronta vs urheiluhieronta</h1>
        <HieronnatP1 />
        <h4>Mutta mikä todellisuudessa on urheiluhierontaa? </h4>
        <HieronnatP2 />
        <h4>Mitä klassinen hieronta sitten on?</h4>
        <HieronnatP3 />
      </div>
    </div>
  );
};

export default Hieronnat;
