import React from "react";

function DryNeedlingP6() {
  return (
    <p className="kontra">
      - verenvuotosairaus
      <br />
      - lääkitys, joka altistaa verenvuodolle
      <br />
      - neulapelko
      <br />
      - asiakas on haluton hoidolle
      <br />
      - hoitajan ja potilaan välinen huono kommunikaatio
      <br />
      - akuutti vamma
      <br />
      - hoidettavassa raajassa lymfanestekierron häiriö
      <br />
      - infektio
      <br />- hoito on muista syistä sopimaton tilanteeseen nähden
    </p>
  );
}

export default DryNeedlingP6;
