import React, { useState } from "react";
import "./Asiakaskokemukset.css";
import emailjs from "emailjs-com";

function FeedbackForm() {
  console.log(process.env);
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_l83np56",
        "template_apke5uk",
        e.target,
        "6-0Rqn3-HbfuIykRM"
      )
      .then((res) => {
        console.log(res);
        e.target.reset();
      })
      .catch((err) => console.log(err));
  };
  // TODO Validointi ettei formi ole tyhjä lähetettäessä
  return (
    <div className="form-container ">
      <form onSubmit={sendEmail}>
        <label className="form-label">Nimi</label>
        <input
          type="text"
          placeholder="Erkki Esimerkki"
          name="name"
          className="form-control mb-2"
          required
        />
        <label className="form-label">Sähköpostiosoite</label>
        <input
          type="email"
          placeholder="esimerkki@esimerkki.fi"
          name="user_email"
          className="form-control mb-2"
          required
        />
        <label className="form-label">Palauteviesti</label>
        <textarea
          name="message"
          rows="6"
          placeholder="Vapaamuotoinen palaute"
          className="form-control mb-4"
          required
        />

        <input type="submit" value="Send" className="btn btn-primary mb-4 " />
      </form>
    </div>
  );
}

export default FeedbackForm;
