//import React, { useState, useEffect } from 'react';
import palaute from "../../Images/palaute.jpg";
import "./Asiakaskokemukset.css";
import Janna from "./Feedbacks/Janna";
import Teemu from "./Feedbacks/Teemu";
import Laura from "./Feedbacks/Laura";
import Sini from "./Feedbacks/Sini";
import SiniK from "./Feedbacks/SiniK";
import Katri from "./Feedbacks/Katri";
import Tuija from "./Feedbacks/Tuija";
import Susanna from "./Feedbacks/Susanna";
import Minna from "./Feedbacks/Minna";
import Kaisa from "./Feedbacks/Kaisa";
import Paivi from "./Feedbacks/Paivi";
import FeedbackForm from "./FeedbackForm";
import "aos/dist/aos.css";
import Aos from "aos";
import { useEffect } from "react";

function Asiakaskokemukset() {
  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <div>
      <div
        className="flex-container"
        style={{
          background: "linear-gradient(to left, #1AAFE7 10%, white 90%)",
        }}
      >
        <h2> Asiakaspalaute</h2>
        <img className="palauteKuva" src={palaute} alt="" />
        <br />
        {/* <h3>Anna palautetta</h3>
				<p className="fs-6">Palaute tulee Lennun sähköpostiin</p>
				<FeedbackForm /> */}
        <div className="grid-container">
          <Janna />
          <Laura />
          <SiniK />
          <Tuija />
          <Teemu />
          <Sini />
          <Katri />
          <Susanna />
          <Minna />
          <Kaisa />
          <Paivi />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default Asiakaskokemukset;
