import React from "react";
import lihassarkomeereja from "../../../Images/Lihassarkomeereja.jpg";
import neula from "../../../Images/neula.jpg";
import DryNeedlingP1 from "./DryNeedlingP1";
import DryNeedlingP2 from "./DryNeedlingP2";
import DryNeedlingP3 from "./DryNeedlingP3";
import DryNeedlingP4 from "./DryNeedlingP4";
import DryNeedlingP5 from "./DryNeedlingP5";
import DryNeedlingP6 from "./DryNeedlingP6";
const DryNeedling = () => {
  return (
    <div>
      <h1>Mikä ihmeen Dry needling?</h1>
      <DryNeedlingP1 />
      <br />
      <label className="sarko">
        <img src={neula} className="neula" alt="" />
        <br />
        0,3mm paksuinen neula
      </label>
      <br />
      <h3>Hoitomenetelmän historia lyhyesti</h3>
      <DryNeedlingP2 />
      <br />
      <h3>Hoito käytännössä ja sen vaikutus kehoon</h3>
      <DryNeedlingP3 />
      <br />
      <h3>Mitä triggerpisteet ovat</h3>
      <DryNeedlingP4 />
      <label className="sarko">
        <img src={lihassarkomeereja} className="lihassarkoomereja" alt="" />
        <br />
        Lyhentyneessä tilassa olevia lihassarkoomereja, jotka voidaan tuntea
        ihon läpi kireänä lihasjuosteena
      </label>
      <br />
      <h3>Hoidettavat alueet</h3>
      <DryNeedlingP5 />
      <br />
      <h3>Kontraindikaatiot eli milloin Dry Needling-hoitoa ei saa tehdä</h3>
      <DryNeedlingP6 />
    </div>
  );
};

export default DryNeedling;
