import React from "react";

export default function Testi() {
  return (
    <p>
      LennuSport kehittyy. En ole kokonaan unohtanut Personal
      Trainer-palveluita, mutta nyt ainakin hetkeen keskityn hierontaan. (Mikäli
      tarvitset PT-palveluita, ota yhteyttä, niin katsotaan mitä voin tehdä).
      Kehittyminen ei kuitenkaan muuta sitä tosiasiaa, että haluan lisätä
      ihmisten hyvinvointia parhaani mukaan. Siispä päätin postata tänne
      hyvinvointiin liittyvistä asioista, jotka pyörivät minun mielessäni tai
      joita minulta toivotaan. Ensimmäiset aiheet ovat erittäin lähellä sydäntä
      kotonani asuvien 11- ja 12-vuotiaiden, urheilevien nuorten neitien vuoksi.
      Puhun urheilevien lasten ja nuorten rasitusvammoista ja kiputiloista.
      <br />
      Jos Sinulla on mielessä aihe, josta toivoisit minun kirjoittavan, kerro
      siitä ihmeessä!
    </p>
  );
}
