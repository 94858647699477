import React from 'react';
import './Home.css';
import Lennu from '../../Images/Lennu.jpg';
import Lennulogo from '../../Images/lennu_logo.png';
import Info from './Info';

function Home() {
	return (
		<div className="container">
			<img className="lennuKuva" src={Lennu} alt="Lennukuva" />
			<Info />
			<img className="lennuKuva2" src={Lennulogo} alt="Lennusport" />
		</div>
	);
}

export default Home;
