import React from "react";
import rasitusvamma from "../../../Images/Rasitusvammat1.jpg";
import osgood from "../../../Images/osgood.jpg";
import "../Blogi.css";
import LastenRasitusvammatP1 from "./LastenRasitusvammatP1";
import LastenRasitusvammatP2 from "./LastenRasitusvammatP2";
import LastenRasitusvammatP3 from "./LastenRasitusvammatP3";
import LastenRasitusvammatP4 from "./LastenRasitusvammatP4";

const Rasitusvammat = () => {
  return (
    <div
      className="teksti-container"
      style={{ background: "linear-gradient(to left, #1AAFE7 10%, white 90%)" }}
    >
      <div className="para">
        <h3>Jatkoa lasten kasvukivuille – lasten yleisimmät rasitusvammat</h3>
        <LastenRasitusvammatP1 />
      </div>
      <div className="para">
        <img src={rasitusvamma} className="rasitusvamma" alt="Rasitusvamma" />
        <h3>Severin tauti:</h3>
        <LastenRasitusvammatP2 />
      </div>
      <div className="para">
        <h3>Osgood-Schlatterin tauti:</h3>
        <LastenRasitusvammatP3 />
        <img src={osgood} className="osgoodvamma" alt="OsGood" />
      </div>
      <div className="para">
        <h3>Hoito: </h3>
        <LastenRasitusvammatP4 />
      </div>
    </div>
  );
};
export default Rasitusvammat;
