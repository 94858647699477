import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Kasvukivut from "./Kasvukivut/Kasvukivut";
import Rasitusvammat from "./LastenRasitusvammat/Rasitusvammat";
import Alkusivu from "./Alkusivu";
import TabPanel from "./TabPanel";
import Hermot from "./Hermot/Hermot";
import Hieronnat from "./Hieronnat/Hieronnat";
import DryNeedling from "./DryNeedling/DryNeedling";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Blog() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{ background: "linear-gradient(to left, #1AAFE7 10%, white 90%)" }}
    >
      <AppBar
        position="static"
        color="default"
        style={{
          background: "linear-gradient(to left, #1AAFE7 10%, white 90%)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Alkusivu" {...a11yProps(0)} />
          <Tab label="Kasvukivut" {...a11yProps(1)} />
          <Tab label="Lasten rasitusvammat" {...a11yProps(2)} />
          <Tab label="Hermot" {...a11yProps(3)} />
          <Tab label="Hieronnat" {...a11yProps(4)} />
          <Tab label="Dry Needling" {...a11yProps(5)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Alkusivu />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Kasvukivut />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Rasitusvammat />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Hermot />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Hieronnat />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DryNeedling />
      </TabPanel>
    </div>
  );
}
