import React from "react";

function Teemu() {
  return (
    <div data-aos="zoom-in-left" data-aos-duration="3000" className="paragraph">
      <h2> Teemu</h2>
      Lennulta löytyy voimaa ja taitoa käsitellä myös isommankin miehen
      lihaksia. Hän löytää käsittämättömästi sellaisiakin kipeitä kohtia, joiden
      olemassaolosta en itse edes tiennyt. Vahvan anatomian osaamisen huomaa
      otteista. Suosittelen lämpimästi kaikille.
    </div>
  );
}
export default Teemu;
