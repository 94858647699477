import React from "react";

function HieronnatP2() {
  return (
    <p>
      <p>
        Se on hierontaa, joka on tarkoitettu nimenomaan urheilijoille. Siinä
        otetaan huomioon vahvasti lajivaatimukset ja treeni- ja kisasyklit. Joka
        lajissa on omat akillen kantapäänsä - vammautumiselle alttiit paikat.
        Niiden huoltoon panostetaan. Usein annetaan palauttavaa hierontaa, jotta
        urheilija palautuisi nopeammin ja tehokkaammin suorituksen jälkeen.
      </p>

      <p>
        En ole (vielä) suorittanut urheiluhieronnan erikoistutkintoa, joten
        yksityiskohdat jätän sen suorittaneille. Koulutuksesta voin kuitenkin
        kertoa sen verran, että sen kesto on 180 osp (1-1,5 vuotta). Ja
        koulutukseen pääsee kun hyppysissä on jo valmiiksi koulutetun hierojan
        tutkinto (joka kestää 1 vuoden). Hauskaksi asian tekee se, että vielä
        muutama vuosi sitten koulutetun hierojakoulutuksen käyneet, saivat
        käyttää urheiluhieroja-nimikettä. Enää ei - vaikka koulutuksen sisältö
        ei ole muuttunut. Urheiluhieronnan erikoistutkinnon käyneen hierojan
        tunnistaa kolmesta kirjaimesta ammattinimikkeen perässä - EAT.
      </p>
    </p>
  );
}

export default HieronnatP2;
