import React from "react";

function HieronnatP1() {
  return (
    <p>
      <p>
        Minulta on useasti kysytty, tarjoanko klassista vai urheiluhierontaa.{" "}
      </p>

      <p>
        Yleensä olen esittänyt vastakysymyksen - mitä ajattelet, että nämä
        tarkoittavat - miten eroavat toisistaan. Lähes aina vastaus on ollut
        sama.
      </p>

      <p>
        Klassiseksi hieronnaksi mielletään rentouttava sively, spa-hieronnat,
        hyvän olon hieronnat, ts. sellainen hieronta joka tuntuu hyvältä, mutta
        josta ei ole niinkään paljon hyötyä jos lihakset ovat kipeät ja tukossa.{" "}
      </p>

      <p>
        Urheiluhieronnaksi mielletään lihasten avaukset, vahvat otteet, osa on
        maininnut myös faskia- eli lihaskalvokäsittelyt. Siispä minä tarjoan
        urheiluhierontaa - kuten timman ajanvaruksessakin lukee (yritin vaihtaa
        sen pelkäksi hieronnaksi, mutta järjestelmä ei taipunut siihen).
      </p>
    </p>
  );
}

export default HieronnatP1;
