import React from "react";

function SiniK() {
  return (
    <div
      data-aos="zoom-in-right"
      data-aos-duration="2000"
      className="paragraph"
    >
      <h2> Sini K</h2>
      Taivaallinen löytö! Asiantunteva ja monipuolinen hierontatyyli, palvelu
      oli täysin sitä mitä luvattiin ellei jopa enemmänkin. Asiakaslähtöisempää
      palvelua ei olisi voinut toivoa, lihasjumien poistamisen ja rentoutumisen
      lisäksi oppi itsekin samalla uusia asioita kropastaan. Heti ensimmäisellä
      tapaamisella synkkasi niin hyvin, että tiedän missä käyn jatkossakin 💪🏻
    </div>
  );
}

export default SiniK;
