import React from "react";

function LastenRasitusvammatP1() {
  return (
    <p>
      Rasitusvamma tarkoittaa liikuntaan tai urheiluun liittyvä oire, johon ei
      liity yksittäistä akuuttia alkamisajankohtaa. Noin puolet lasten ja
      nuorten urheiluvammoista ovat rasitusvammoja. Tytöillä rasitusvammat ovat
      hiukan yleisempiä kuin pojilla. Kaikista yleisimpiä rasitusvammoja ovat
      apofysiitit eli kasvutumakkeen vaurioitumiset. Avataanpa asiaa hieman.
      Paljon liikkuvilla lapsilla ja nuorilla on usein kipuiluja polven
      alapuolella sääressä sekä kantapään seuduilla. Kivut johtuvat siitä, että
      luissa on luutumisalueita, jotka mahdollistavat pituuskasvun ja nämä
      luutumisalueet ovat heikompia kuin alueen jänteet ja nivelsiteet.
      Kipukohta sijaitseekin yleensä luun kasvualueella, missä jänne kiinnittyy
      luuhun. Kymmenen vuoden hujakoilla lapsilla oireilee usein kantaluun
      takaosa, johon kiinnittyy akillesjänne. Vaivaa kutsutaan myös Severin
      taudiksi. Muutama vuosi myöhemmin yleisin kipualue on sääriluun
      säärikyhmy, johon polvijänne kiinnittyy. Vaivan nimi on Osgood-Schlatterin
      tauti. Vaivat ovat hyvälaatuisia ja väistyvät pituuskasvun päätyttyä.
      Tyypillinen oireiden kesto on parista kuukaudesta jopa pariin vuoteen.
    </p>
  );
}

export default LastenRasitusvammatP1;
