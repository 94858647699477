import React from 'react';

function Ehdot() {
	return (
		<div className="hinnasto-container">
			<p className="kasittely">
				*Käsittely on hieronta tai dry needling tai näiden yhdistelmä <br />
				*Sarjakortit voimassa 9kk ostopäivästä
			</p>
			<div>
				<h2>Ajan peruminen</h2>
				<p>Ajan peruminen onnistuu ajanvarausjärjestelmä timmasta tai puhelimitse.</p>
				<p>Muistathan perua aikasi viimeistään hoitopäiväsi aamusta 08:00.</p>
				<p>Peruuttamattomasta ajasta veloitetaan täysi hoitohinta.</p>
			</div>
		</div>
	);
}

export default Ehdot;
