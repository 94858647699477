import React from "react";
import sijainti from "../../Images/sijainti.jpg";
import toimipiste from "../../Images/Toimipiste2.jpg";
import "./Yhteystiedot.css";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FacebookIcon from "@material-ui/icons/Facebook";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ImageZoom from "react-image-zooom";

function Yhteystiedot() {
  return (
    <div
      style={{ background: "linear-gradient(to left, #1AAFE7 10%, white 90%)" }}
    >
      <h2> Yhteystiedot</h2>
      <p>LennuSport Massage</p>
      <p>Lepsämäntie 1, 01800 Klaukkala</p>
      <p>Hesburgerin yläpuolella toisessa kerroksessa</p>
      <p>Jos ovi on lukossa, soita ovikelloa</p>
      <ImageZoom className="sijainti" src={sijainti} alt="Location" />
      <br />
      <ImageZoom className="toimipiste" src={toimipiste} alt="Toimipiste" />
      <br />
      <br />
      <br />
      <div
        className="mobile"
        style={{
          background: "linear-gradient(to left, #1AAFE7 10%, white 90%)",
        }}
      >
        <PhoneIcon className="phone" /> +358 503719626
        <EmailIcon className="email" /> lennu_g@hotmail.com
        <br />
        <FacebookIcon className="facebook" />
        <a
          href="https://www.facebook.com/LennuSport.Massage"
          target="_blank"
          rel="noreferrer"
        >
          LennuSport
        </a>
        <LocationOnIcon className="location" /> Lepsämäntie 1, 01800 Klaukkala
        <ScheduleIcon className="varaa" />
        <a
          className="timma"
          href="https://varaa.timma.fi/lennusport"
          target="_blank"
          rel="noreferrer"
        >
          Varaa aika
        </a>
      </div>
    </div>
  );
}

export default Yhteystiedot;
