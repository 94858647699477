import React from "react";
import blogikuva from "../../Images/Blogikuva2.jpg";
import "./Blogi.css";
import AlkusivuText from "./AlkusivuText";
const Alkusivu = () => {
  return (
    <div
      className="alku-container"
      style={{ background: "linear-gradient(to left, #1AAFE7 10%, white 90%)" }}
    >
      <div className="para">
        <h3>LennuSport Massage Blogi</h3>
        <AlkusivuText />

        <img src={blogikuva} className="blogikuva" alt="" />
      </div>
    </div>
  );
};
export default Alkusivu;
