import React from "react";
import "../Blogi.css";
import HermotP1 from "./HermotP1";
import HermotP2 from "./HermotP2";
import HermotP3 from "./HermotP3";
import HermotP4 from "./HermotP4";
import HermotP5 from "./HermotP5";
const Hermot = () => {
  return (
    <div
      className="alku-container"
      style={{ background: "linear-gradient(to left, #1AAFE7 10%, white 90%)" }}
    >
      <div className="para">
        <h1>(Meneekö) hermot?</h1>
        <HermotP1 />
        <h2>Yläraajahermot</h2>
        <HermotP2 />
        <h4>Keskihermo</h4>
        <HermotP3 />
        <h4>Värttinähermo</h4>
        <HermotP4 />
        <h4>Kyynärhermo</h4>
        <HermotP5 />
      </div>
    </div>
  );
};

export default Hermot;
