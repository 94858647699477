import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './Hinnasto.css';
import Maksutavat from './Maksutavat';
import Ehdot from './Ehdot';

function createData(kesto, hinta) {
	return { kesto, hinta };
}

const kertahinnasto = [
	createData('30 min', '37 €'),
	createData('45 min', '47 €'),
	createData('60 min', '57 €'),
	createData('75 min', '67 €'),
	createData('90 min', '77 €')
];
const sarjahinnasto = [
	createData('5x30min', '170 €'),
	createData('5x45min', '220 €'),
	createData('5x60min', '270 €'),
	createData('5x75min', '320 €'),
	createData('5x90min', '370 €')
];

function Hinnasto() {
	return (
		<div>
			<Maksutavat />
			<div className="uusTaulu-container">
				<TableContainer>
					<Table className="taulu2" aria-label="Hinnasto">
						<TableHead>
							<TableRow>
								<TableCell>
									<b>Käsittelyn kesto (min)</b>
								</TableCell>
								<TableCell align="right">
									<b>Hinta</b>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{kertahinnasto.map((row) => (
								<TableRow key={row.name}>
									<TableCell component="th" scope="row">
										{row.kesto}
									</TableCell>
									<TableCell align="right">{row.hinta}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TableContainer>
					<Table className="taulu2" aria-label="Hinnasto">
						<TableHead>
							<TableRow>
								<TableCell>
									<b>Käsittelyn kesto (min)</b>
								</TableCell>
								<TableCell align="right">
									<b>Hinta</b>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sarjahinnasto.map((row) => (
								<TableRow key={row.name}>
									<TableCell component="th" scope="row">
										{row.kesto}
									</TableCell>
									<TableCell align="right">{row.hinta}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			<br />
			<Ehdot />
		</div>
	);
}

export default Hinnasto;
