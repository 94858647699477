import React from "react";

function HermotP2() {
  return (
    <div>
      <p>
        Kaularangan alaosista ja rintarangan ylimmästä nikamavälistä lähtee
        olkapunos eli useampi hermo yhteenkiedottuna. Hermot menevät
        kaulalihasten (scaleniukset eli kylkiluun kannattajalihakset) välistä ja
        sukeltavat solisluun alle, kulkevat pienen rintalihaksen alta ja
        siirtyvät siitä kainalon kautta käteen omina hermoina. Hermot ovat ns.
        sensomotorisia mikä tarkoittaa sitä, että hermossa on sekä tuntevia
        ominaisuuksia että liikekäskyjä välittäviä ominaisuuksia. Tässä
        vaiheessa otetaan pieni aikalisä ja puhutaan hetki hermopinteistä.
      </p>
      <p>
        Hermopinne tarkoittaa sitä, että hermo joutuu puristuksiin. Tämä voi
        tapahtua monessa paikassa ja monesta eri syystä, mutta tässä yleisimmät
        siinä järjestyksessä, kun etenemme. Nikamavälit saattavat madaltua ja
        hermopunos voi jäädä pinteeseen niiden väliin. Silloin puutumista tai
        kipua voi tuntua koko kädessä. Pahimmillaan koko käsi voi olla ns. pois
        pelistä, jos myös syvemmällä hermorakenteissa kulkevat motoriset
        (liikekäskyjä välittävät) hermosyyt joutuvat puristuksiin. Päälaesta
        alaspäin painaminen tai pään taivuttaminen sivulle pahentaa oireita.
        Lievät madaltumat saa ”esiin” katsomalla ensin sivulle ja kallistamalla
        sen jälkeen päätä katseen suhteen vastakkaiseen suuntaan. Seuraava
        pinneriskipaikka ovat kaulan lihakset, sillä ne ovat vahvat ja jos ne
        ovat kireänä, hermon kulkuväylä kapenee. Hieronta auttaa tähän vaivaan
        erinomaisesti. Sitten mennäänkin solisluun ja pienen rintalihaksen alle.
        Rakenteelliset muutokset sellaisenaan voivat aiheuttaa sen, että hermon
        kulkuväylä on ahdas. Sen lisäksi tiukka pieni rintalihas vetää koko
        olkapääkompleksia eteenpäin (eteenpäin kääntyneet olkapäät) ja pienentää
        entisestään hermon kulkuväylää. Hieronta auttaa tähänkin.
      </p>
      <p>
        Noniin – aikalisä ohi. Jatketaan eteenpäin. Ollaan päästy olkavarteen ja
        hermopunos on eriytynyt hermoiksi. Kaikkia pikkuhermoja en käsittele,
        ainoastaan suurimmat.
      </p>
    </div>
  );
}

export default HermotP2;
