import './App.css';
import React from 'react';
import Menu from './Components/Menu/Menu';

function App() {
	return (
		<div className="App" style={{ background: 'linear-gradient(to left, #1AAFE7 10%, white 90%)' }}>
			<Menu />
		</div>
	);
}
export default App;
