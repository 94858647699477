import React from "react";

function Minna() {
  return (
    <div
      data-aos="zoom-in-right"
      data-aos-duration="5000"
      className="paragraph"
    >
      <h2> Minna</h2>
      Ensimmäistä kertaa pariin vuoteen herään aamuun ilman selkäkipuja, kiitos
    </div>
  );
}
export default Minna;
