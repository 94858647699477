import React from "react";
import Kasvukipu from "../../../Images/kasvukivut.jpg";
import "../Blogi.css";
import KasvukivutP1 from "./KasvukivutP1";

const Kasvukivut = () => {
  return (
    <div
      className="alku-container"
      style={{ background: "linear-gradient(to left, #1AAFE7 10%, white 90%)" }}
    >
      <div className="para">
        <h3>Kasvukivut – mitä ne ovat?</h3>
        <KasvukivutP1 />
        <br />
        <br />
        P.S. Hieron enemmän kuin mielelläni lapsia ja nuoria 😉
      </div>

      <img src={Kasvukipu} className="kasvukipu" alt="" />
    </div>
  );
};
export default Kasvukivut;
