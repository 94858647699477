import React from "react";

function Laura() {
  return (
    <div
      data-aos="zoom-in-right"
      data-aos-duration="1500"
      className="paragraph"
    >
      <h2> Laura</h2>
      Olen käynyt nyt Lennulla hieronnassa useamman kerran ja voin todellakin
      suositella hänen käsittelyään. Hänellä on loistavat kädet. Olen paljon
      käynyt hierojilla ja nyt olen onneksi löytänyt Klaukkalasta sen omani.
      Asianosaaminen ja asiakkaan kehon kuunteleminen on parhaat ominaisuudet.
    </div>
  );
}

export default Laura;
