import React from "react";
import "./Home.css";

const Info = () => {
  return (
    <div className="teksti-container">
      <div className="para1">
        <b>LennuSportista</b>
      </div>

      <div className="para1">
        LennuSport Massage on Klaukkalassa toimiva hierontayritys. Tarjoan
        tehokasta hierontaa personal trainer-twistillä ja vahvalla
        terveydenhoitotaustalla. Repertuaariin kuuluvat hieronnan lisäksi myös
        dry needling, länsimainen akupunktio sekä electroacupunktio (ehkä
        tehokkain keino, mihin olen törmännyt!). Käytän myös mobilisoivia
        tekniikoita ja kuivakuppausta tehokeinoina. Toimitilani sijaitsee
        Klaukkalassa, osoitteessa Lepsämäntie 1 (2. kerros Hesburgerin
        yläpuolella).
        <br />
      </div>
      <div className="para1">
        Muutama sananen taustastani. <br />
        Olen nelikymppinen, kahden murrosikäisen tytön äiti. Taustaltani olen
        terveydenhuollon ammattilainen – ensihoitaja sekä anestesiahoitaja. Olen
        työskennellyt ambulanssissa ja leikkaussaleissa lähes 20 vuotta. Työn
        ohessa olen kouluttautunut personal traineriksi ja työskennellyt
        liikunta-alalla sivutoimisesti useamman vuoden ajan. Hierojakoulutuksen
        jälkeen olen opiskellut Dry Needling-terapeutiksi(
        <a href="/Blog">Blogistani</a> löytyy lisätietoa Dry Needlingistä) ja
        sen innoittamana opiskelen parhaillaan Länsimaista akupunktiota. Tykkään
        olla tekemisissä ihmisten kanssa ja sisälläni on aito halu auttaa,
        poistaa kipua ja lisätä hyvinvointia.
      </div>
      <div className="para1">
        <b>Hierontafilosofiastani:</b> <br />
      </div>
      <div className="para1">
        Hierontatyyliäni voisin kuvailla vahvaksi, mutta fiksuksi. En usko
        siihen, että vain kipua tuottava hieronta auttaa. Yksinkertaisesti sen
        vuoksi, että jos kipua tuotetaan, lihas jännittyy, eikä sen käsittelyllä
        ole silloin toivottavaa hyötyä. Hellä sively taas voi olla hyvinkin
        paikallaan, mikäli asiakas kaipaa rentouttavaa hetkeä ja tarkoituksena
        on hieman vilkaistutta pintaverenkiertoa tai muokkaavampi hieronta on
        terveyssyistä kielletty. Sively on myös mukavantuntuista, mutta
        kovinkaan tehokasta se ei valitettavasti ole. Suurin osa ihmisistä tulee
        hierojalle lihaskireyksien vuoksi. Vahva, mutta pehmeä, muokkaava ja
        ennen kaikkea anatomiaan pohjautuva hieronta eri tekniikoita
        yhdistelemällä on mielestäni silloin paras tapa tuoda helpotusta.
        Neulotushoito eri muodossa on erittäin tehokas hoitokeino – paitsi
        tietty, jos asiakas ei pidä neuloista. Suosittelen kokeilemaan, jos et
        ole vielä kokeillut!
      </div>
      <div className="para1">
        Olet lämpimästi tervetullut hoitoon! Mikäli timmasta ei löydy Sinulle
        sopivaa aikaa, ota rohkeasti yhteyttä, niin katsotaan mitä voin tehdä.
        <br /> <br />
      </div>
    </div>
  );
};

export default Info;
