import React from "react";

function Kaisa() {
  return (
    <div data-aos="zoom-in-left" data-aos-duration="6000" className="paragraph">
      <h2> Kaisa</h2>
      (Käsittelyn jälkeen) Mielestäni jäykkyys koko selkärangassa on poistunut,
      ja kipu vasemmalla ristiselässä tuntuu melkoharvoin. (Toisen käynnin
      jälkeen) nyt on selkä täysin terve, suuret kiitokset sinulle.
    </div>
  );
}

export default Kaisa;
