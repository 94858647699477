import React from "react";

function DryNeedlingP5() {
  return (
    <p>
      Hoito voidaan turvallisesti tehdä lähes kaikille kehon alueille.
      Käsittelyn yleinen sivuvaikutus <br />
      on ohimenevä arkuus neulatulla alueella. Tyypillisiä hoidettavia alueita
      ovat ylä- ja alaraajat, <br />
      rintalihakset, selän, hartian ja niskan seutu sekä kasvojen alueella
      purentalihakset. Vaikeissa <br />
      jännitystiloissa esimerkiksi "penikkatauti", voidaan neulahoitoon yhdistää
      sähköä TENS/EMS-laitteella. <br />
      Tällöin lihakseen pystytään aikaansaamaan voimakkaampaa stimulaatiota.
      Tyypillisiä hoidettavia vaivoja <br />
      ovat muun muassa hartia- ja selkäkivut, migreeni, tenniskyynärpää,
      penikkatauti ja muut lihasperäiset <br />
      kiputilat sekä nivelsäryt. Hoitomuoto soveltuu erinomaisesti myös
      urheilijoille.
    </p>
  );
}

export default DryNeedlingP5;
