import React from "react";

function LastenRasitusvammatP3() {
  return (
    <p>
      On yleisin nuorten rasitusvamma. Kipu paikallistuu säärikyhmyyn, polven
      alapuolelle, mihin polvilumpion jänne kiinnittyy (etureisilihasten jänne).
      Juoksu tuottaa kipua, mutta kyykyt ja hypyt ovat kaikkein pahimpia kivun
      aiheuttajia. Samoin kuin Severin taudissa, pahentuessaan kipu jatkuu myös
      rasituksen loputtua. Tämä vaiva voi kestää pidempään kuin Severin tauti.
    </p>
  );
}

export default LastenRasitusvammatP3;
