import React from "react";

function DryNeedlingP4() {
  return (
    <p>
      Triggerpisteet ovat lihaksessa olevia herkkiä tai kipeitä pisteitä, jotka
      tuntuvat pinnallisissa lihaksissa kireinä juosteina tai ”herneinä”.
      <br />
      Kireät juosteet ovat jatkuvasti lyhentyneessä tilassa olevia lihassyitä.
      Tämä lyhentynyt tila estää lihasta toimimasta sille ominaisella tavalla{" "}
      <br />
      heikentämällä sen aineenvaihduntaa paikallisesti. Lihassolut eivät saa
      happea ja kuona-aineita kertyy lisää ja lisää.
      <br />
      Triggerpisteet aktivoituvat yleensä toistuvan rasituksen, stressin tai
      kohonneen verenpaineen aiheuttamana tai kehon ph-arvon laskemisen <br />
      seurauksena (happamoituminen/asidoosi). Myös kroonisesta kivusta
      kärsiville kehittyy triggerpisteitä. Trigger-pisteille on tyypillistä
      heijastekipu. <br />T ämä tarkoittaa sitä, että hoidettava piste voi
      sijaita yllättävän kaukana varsinaisesta kipualueesta.
    </p>
  );
}

export default DryNeedlingP4;
