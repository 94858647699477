import React from "react";

function HermotP5() {
  return (
    <p>
      <p>
        Viimeisenä käsitellään vielä kyynärhermo. Se kulkee aivan olkavarren
        sisäreunalla, kuten keskihermokin. Se kiertää olkaluun alapuolella
        olkaluun sisäniivelnastan (luun päässä tuntuva möhkälepaksuuntuma),
        jatkaa siitä kyynärvarteen pikkurillin puolella ranteen
        koukistajalihasten suojassa ja etenee kämmenen alueelle ns. Guyonin
        kanavan kautta. Guyonin kanava kulkee herneluun ja hakaluun välissä.
        Herneluun tuntuu pienenä ulokkeena kämmenen pikkurillinpuoleisessa
        osassa, juuri ennen kuin kämmen muuttuu ranteeksi. Pyörällä ajaessa
        tietyssä asennossa paine kohdistuu Guyonin kanavaan ja voi aiheuttaa
        käden puutumista. Toinen paikka, jossa hermo voi ottaa osumaa, on
        kyynärpää. Veikkaan, että jokainen on joskus lyönyt kyynärpäänsä niin,
        että on kyllä tuntunut ihan pikkurillissä asti. Kyynärhermo sijaitsee
        olkapunoksessa alimmaisena, joten se jää helpommin jumiin kaula-alueella
        kuin keski- tai värttinähermo.
      </p>
      <p>
        Kyynärhermon hermottama alue on pikkurillin puoleinen nimettömän osa ja
        pikkurilli kokonaan sekä pikkurillin puoleinen osa kämmenestä ja
        kämmenselästä.
      </p>
      <p>
        Koska aihe on hankalalukuinen, jatkan alaraajan hermoista myöhemmin.{" "}
      </p>
      <p>
        Toivottavasti kirjoitukseni avaavat hiukan asiaa ja auttavat ymmärtämään
        kokonaisuutta.
      </p>
      <p>
        Jälleen kerran – tervetuloa hierontaan, jos oireilet – tai vaikka et
        oireilisikaan 😊
      </p>
    </p>
  );
}

export default HermotP5;
