import React from "react";

function DryNeedlingP2() {
  return (
    <p>
      Vaikka tämä saattaa olla ensimmäinen kerta, kun törmäät Dry
      Needling-hoitomuotoon, se ei ole mikään uusi keksintö. <br />
      Dry Needling on saanut alkunsa 1940-luvulla. Sitä on tutkittu ja käytetty
      pitkään ja se onkin maailmanlaajuisesti hyväksytty yhdeksi
      kivunhoitomenetelmäksi. <br />
      Ensimmäiset Suomessa koulutetut Dry Needling-hoitajat valmistuivat
      joulukuussa 2017.
    </p>
  );
}

export default DryNeedlingP2;
