import React from "react";

function Susanna() {
  return (
    <div
      data-aos="zoom-in-right"
      data-aos-duration="4500"
      className="paragraph"
    >
      <h2> Susanna</h2>
      Olen ollut todella tyytyväinen LennuSport hierontaan. Muutaman kerran olen
      käynyt ja varmasti käyn jatkossakin 😊
    </div>
  );
}

export default Susanna;
