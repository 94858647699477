import React from "react";

function HermotP3() {
  return (
    <p>
      Kulkee olkavarren sisäreunassa, ylittää kyynärpään alueen kyynärtaipeen
      puolella. Kulkee hauislihaksen alapuolisen kiinnityskohdan alta ja liereän
      sisäänkiertäjälihaksen päiden välistä. Jatkaa siitä sormien pinnallisten
      ja syvien koukistajalihasten välissä keskellä kyynärvartta ja päätyy
      kämmenen alueelle rannekanavan läpi. Tyypillisiä hermopinnepaikkoja
      juurikin tämä rannekanava (kuvitelkaa parin sentin mittaista putkea, johon
      on pakattu täyteen hermoja, laskimoita, valtimoita, jänteitä ym.)
      Rannekanavan lisäksi myös kyynärvarren lihasten kireys voi aiheuttaa
      pinnetilan. Hieronta ja jumppa voivat auttaa. Välillä kuitenkin päädytään
      kirurgiseen hoitoon, jossa rannekanavaa avarretaan poistamalla siitä
      ylimääräistä kudosta. <br />
      Hermon pinne voi teoriassa aiheuttaa oireita minne vaan sen kulkualueella.
      Yleisin paikka on kuitenkin hermon ääreisosat. Keskihermon kohdalla tämä
      tarkoittaa kämmenen puolen peukalon päätä, etusormen, keskisormen ja
      osittain nimettömän aluetta sekä peukalonpuoleista aluetta kämmenessä.
    </p>
  );
}

export default HermotP3;
