import React from "react";

function Sini() {
  return (
    <div data-aos="zoom-in-left" data-aos-duration="2500" className="paragraph">
      <h2> Sini</h2>
      Olen käynyt Lennun hierottavana nyt kaksi kertaa. Etätyön vuoksi olen
      huomannu kärsiväni niska-hartia seudun jumeista selkeästi enemmän. Useasti
      on palan tunne kurkussa, tai kurkkua kursitaa. Kun olen käynyt Lennun
      hierottavana ovat oireet poistuneet. Lennu on hieroja joka kuuntelee ja
      keskittyy asiakkaaseen ammattitaitoisesti ja hoitaa asiakastaan
      yksilöllisesti. Voin lämpimästi suositella.
    </div>
  );
}

export default Sini;
