import React from "react";

function HermotP4() {
  return (
    <p>
      Siirrytään eteenpäin, puhutaan värttinähermosta. Se kulkee ison
      liereälihaksen alta olkaluun taakse (eli ojentajan puolelle), jossa se
      sukeltaa syvemmälle kalvoaukosta ja haarautuu kyynärpään alueella,
      olka-värttinäluulihaksen seuduilla, pinnalliseen ja syvään haaraan. Syvä
      haara jää kyynärvarren alueelle ja pinnallinen kulkee
      olka-värttinäluulihaksen alle ja kiertyy siitä kämmenselän puolelle.{" "}
      <br />
      Pientä apua anatomian hahmottamiseen pinnepaikkojen ymmärtämiseksi. Iso
      liereälihas on osa kiertäjäkalvosinta. Oletkin ehkä kuullut puhuttavan
      siitä olkavaivojen yhteydessä. Se alkaa lapaluun alakulmasta ja muodostaa
      kainalopoimun menemällä olkaluun etupuolelle tukemaan olkapäätä. Lihas on
      usein kireä ja onkin yksi mahdollisista pinnepaikoista.
      Olka-värttinäluulihas taas on kyynärvarren yläpäässä oleva lihas, joka
      kipeytyy kun vasaroit, kannat pitkään jotain tai esim. treenaat käsiä
      salilla. Se on kireä lähes kaikilla ja näin olleen siinä on toinen
      mahdollinen pinnepaikka. Lisäksi olka-värttinälihaksessa on nk. Frohsen
      arkadi – ikään kuin aukko tai kolo, jonka läpi hermo sukeltaa syvemmälle -
      jälleen mahdollinen pinnepaikka. Alueelle voi tulla kipua, joka on
      helposti sekoitettavissa tenniskyynärpään oireiden kanssa.
      (Tenniskyynärpään kipu tuntuu olkaluun alaosassa, värttinähermon kipu
      värttinäluun yläosassa). Yksi potentiaalinen pinnepaikka on ranne – siinä
      hermo kulkee hyvinkin pinnalla ja esimerkiksi tiukka ponnari (jollaisia
      ainakin minun tyttäreni tuppaavat pitämään ranteissaan), voivat painaa
      hermoa. Haluan mainita vielä yhden pinnepaikan – lauantai-illan
      halvaukseksikin kutsutun. Kun ihminen istuu ja nojaa pöytään käsillään
      (pää nojaa kämmeniin ja ojentaja pöydän reunaan). Sitä kun jatkaa
      pidempään, ojentajapuolella kulkeva värttinähermo voi ottaa itseensä sen
      verran pahasti, että se lakkaa hetkeksi toimimasta ja ranne jää
      roikkumaan.
      <br />
      Värttinähermo hermottaa kämmenselän puolella peukalonhankaa sekä peukaloa,
      etusormea ja keskisormea. Käytännössä samaa aluetta kuin keskihermo, mutta
      vain kämmenselän eikä kämmenen puolella.
    </p>
  );
}

export default HermotP4;
