import React from 'react';

function Maksutavat() {
	return (
		<div
			className="hinnasto-container"
			style={{
				background: 'linear-gradient(to left, #1AAFE7 10%, white 90%)'
			}}
		>
			<div className="Hinnasto">
				<h2>Hinnasto</h2>
				<p>
					Maksutavat: Korttimaksu, Mobilepay, Siirto, käteinen, Smartum, ePassi, EdenRed, Eazybreak <br />
					(HUOM. setelit/liikuntaetu eivät käy maksuvälineenä).
				</p>
				<a href="https://varaa.timma.fi/lennusport" target="_blank" rel="noreferrer">
					<h2>Varaa aika</h2>
				</a>
			</div>
		</div>
	);
}

export default Maksutavat;
