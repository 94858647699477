import React from "react";

function DryNeedlingP1() {
  return (
    <p>
      Dry Needling – toiselta nimeltä kuivaneulaus, on länsimaiseen
      lääketieteeseen perustuvaa akupunktioneuloilla tehtävää hoitoa. <br />
      Kuivaneulaukseksi sitä kutsutaan, koska kehon sisään ei injektoida mitään
      vaan hoito perustuu pelkästään neulan aikaansaamaan vaikutukseen. <br />
      Neuloina käytetään ohuita, steriilejä suojakaasuun pakattuja
      kertakäyttöneuloja.
    </p>
  );
}

export default DryNeedlingP1;
