import React from "react";

function HieronnatP3() {
  return (
    <p>
      <p>
        Se voi olla monilla eri tekniikoilla toteutettua käsittelyä. Siihen voi
        sisältyä myös apuvälineiden käyttö, kuten kuuma-kylmähoito,
        kuivakuppaus, kinesioteippaus. Se voi sisältää passivisia venytyksiä ja
        MET-tekniikoita. Se voi olla käytännössä sitä, mitä ihmiset mieltävät
        urheiluhieronnaksi. TAI se voi olla hellää sivelyäkin. Jokaiselle
        hierojaopiskelijalle opetetaan samat asiat, tekniikat ja temput. Mutta
        jokainen hierojaopiskelija / hieroja valitsee niistä itselleen sopivat,
        omaksuu ne, muokkaa niitä ehkä hiukan ja luo näin omaleimaisen tyylinsä.
        Vai oletko itse joskus käynyt kahdella eri hierojalla, joilla olisi
        täysin identtiset otteet ja tyyli? On vaikeaa löytää "se oikea" - oli
        kyse kumppanista, kampaajasta tai hierojasta. Ja jos sellaisen onnistuu
        löytämään, siitä ei hevillä halua päästää irti 🙂{" "}
      </p>

      <p>
        Toivottavasti kirjoitukseni ja sen aihe olivat mielenkiintoisia ja
        mieluisia. Minulle saa ehdottaa aiheita, joista haluaisit tietää
        enemmän.{" "}
      </p>

      <p>Aurinkoista ja väririkasta syksyä Sinulle 🧡</p>
    </p>
  );
}

export default HieronnatP3;
