import React from "react";

function Paivi() {
  return (
    <div data-aos="zoom-in-left" data-aos-duration="5500" className="paragraph">
      <h2> Päivi</h2>
      Eilisen hieronnan jälkeen, ensimmäinen kerta kun ratsailla ei satu selkään
    </div>
  );
}
export default Paivi;
