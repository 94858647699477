import React from "react";

function Tuija() {
  return (
    <div data-aos="zoom-in-left" data-aos-duration="3500" className="paragraph">
      <h2> Tuija</h2>
      Ammattitaitoista, hyvät otteet ja hoitokeinot pureutuu asiakkaan
      ongelmakohtiin, vahva suositus🤩
    </div>
  );
}

export default Tuija;
