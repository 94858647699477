import '../../App.css';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import HomePage from '../Home/Home';
import Asiakaskokemukset from '../Asiakaskokemukset/Asiakaskokemukset';
import Blog from '../Blogi/Blog';
import Hinnasto from '../Hinnasto/Hinnasto';
import Yhteystiedot from '../Yhteystiedot/Yhteystiedot';
import Lennu2 from '../../Images/lennu_logo.png';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, IconButton } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import {
	ContactPhone,
	Description,
	Home,
	Menu,
	Phone,
	Email,
	LocationOn,
	Facebook,
	Schedule,
	Euro,
	ThumbsUpDown
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1
	},
	link: {
		textDecoration: 'none',
		color: theme.palette.text.primary
	},
	drawerPaper: {
		width: 'inherit'
	}
}));

function MenuPage() {
	const classes = useStyles();
	const [ open, setOpen ] = useState(false);

	const handleDrawer = () => {
		setOpen(true);
	};

	return (
		<div className="app-container">
			<div className="appbarUI">
				<AppBar
					position="static"
					style={{
						background: 'linear-gradient(to left, #1AAFE7 10%, white 90%)',
						color: 'black'
					}}
				>
					<Toolbar>
						<IconButton
							edge="start"
							className={classes.menuButton}
							onClick={handleDrawer}
							color="inherit"
							aria-label="menu"
						>
							<Menu />
							<Typography variant="h6" />
						</IconButton>
						<Typography variant="h4" className="titleFont">
							LennuSport Massage
						</Typography>
					</Toolbar>
				</AppBar>
			</div>

			<Router>
				<div
					className="drawerUI"
					style={{
						display: 'flex'
					}}
				>
					<Drawer
						style={{
							width: '250px'
						}}
						anchor="left"
						color="primary"
						open={open}
						onClose={() => setOpen(false)}
						classes={{ paper: classes.drawerPaper }}
					>
						<List>
							<img src={Lennu2} className="LM" alt="LennuSport" />
							<Link to="/" className={classes.link} onClick={() => setOpen(false)}>
								<ListItem button>
									<ListItemIcon>
										<Home />
									</ListItemIcon>
									<ListItemText primary={'Koti'} />
								</ListItem>
							</Link>

							<Link to="/Hinnasto" className={classes.link} onClick={() => setOpen(false)}>
								<ListItem button>
									<ListItemIcon>
										<Euro />
									</ListItemIcon>
									<ListItemText primary={'Hinnasto ja Ajanvaraus'} />
								</ListItem>
							</Link>
							<Link to="/Asiakaskokemukset" className={classes.link} onClick={() => setOpen(false)}>
								<ListItem button>
									<ListItemIcon>
										<ThumbsUpDown />
									</ListItemIcon>
									<ListItemText primary={'Asiakaskokemukset'} />
								</ListItem>
							</Link>
							<Link to="/Blog" className={classes.link} onClick={() => setOpen(false)}>
								<ListItem button>
									<ListItemIcon>
										<Description />
									</ListItemIcon>
									<ListItemText primary={'Blog'} />
								</ListItem>
							</Link>
							<Link to="/Yhteystiedot" className={classes.link} onClick={() => setOpen(false)}>
								<ListItem button>
									<ListItemIcon>
										<ContactPhone />
									</ListItemIcon>
									<ListItemText primary={'Yhteystiedot'} />
								</ListItem>
							</Link>
						</List>
					</Drawer>
				</div>

				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route exact path="/Hinnasto" component={Hinnasto} />
					<Route exact path="/Asiakaskokemukset" component={Asiakaskokemukset} />
					<Route exact path="/Blog" component={Blog} />
					<Route exact path="/Yhteystiedot" component={Yhteystiedot} />
				</Switch>
			</Router>

			<footer
				className="footer"
				style={{
					background: 'linear-gradient(to left, #1AAFE7 10%, white 90%)'
				}}
			>
				<br />
				<Phone className="phone" /> +358 503719626
				<Email className="email" /> lennu_g@hotmail.com
				<br />
				<Facebook className="facebook" />
				<a href="https://www.facebook.com/LennuSport.Massage" target="_blank" rel="noreferrer">
					LennuSport
				</a>
				<LocationOn className="location" /> Lepsämäntie 1, 01800 Klaukkala
				<Schedule className="varaa" />
				<a className="timma" href="https://varaa.timma.fi/lennusport" target="_blank" rel="noreferrer">
					Varaa aika
				</a>
			</footer>
		</div>
	);
}

export default MenuPage;
